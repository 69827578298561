import React, { useEffect } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

const Dashboard = ({ setBreadcrumbItems, user }) => {
  document.title = "LS-DA / Softlogy"

  const userGreeting = `Bienvenido, ${user ? `${user.nombre} ${user.apellido}` : "Usuario"}`

  document.title = "LS-DA - Softlogy"

  useEffect(() => {
    if (user) {
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        {
          title: "Dashboard",
          link: "#",
        },
      ]
      setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [setBreadcrumbItems, user, userGreeting])

  return <React.Fragment></React.Fragment>
}

const mapStateToProps = state => ({
  user: state.Login.user, // Asegúrate de que el estado de usuario esté en `state.Login.user`
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Dashboard)
