import React, { useEffect, useState, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import ReactPaginate from "react-paginate"

import { connect } from "react-redux"
import axios from "axios"
import { utils, writeFile } from "xlsx"
import { useParams, useNavigate } from "react-router-dom"

// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"
const baseURL = process.env.REACT_APP_API_URL

const Sucursales = props => {
  document.title = "Sucursales | LS-DA - Softlogy"

  const { id } = useParams()
  const navigate = useNavigate()

  const [sucursales, setSucursales] = useState([])
  const [searchTerm] = useState("")

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10

  const [loading, setLoading] = useState(true)

  const fetchSucursales = useCallback(
    async currentUser => {
      if (!currentUser || !currentUser.access) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }

      setLoading(true)
      console.log("Fetching sucursales data...")
      try {
        const response = await axios.get(
          `${baseURL}empresas/sucursales?id=${id}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          },
        )
        console.log("Sucursales data fetched:", response.data)
        setSucursales(response.data.sucursales || [])
      } catch (error) {
        console.error("Error fetching sucursales data:", error)
      } finally {
        setLoading(false)
      }
    },
    [id],
  )

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`

      fetchSucursales(currentUser)
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Empresas", link: "#" },
        { title: "Sucursales", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [fetchSucursales, props])

  const handleExportToExcel = () => {
    const worksheet = utils.json_to_sheet(sucursales)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "Sucursales")
    writeFile(workbook, "Sucursales.xlsx")
  }

  const handleRemoveSucursal = index => {
    setSucursales(prev => prev.filter((_, i) => i !== index))
  }

  const filteredSucursales = sucursales.filter(sucursal =>
    sucursal.Nombretienda.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredSucursales.slice(
    indexOfFirstItem,
    indexOfLastItem,
  )

  const data = {
    columns: [
      { label: "Brand ID", field: "brandid", sort: "asc", width: 20 },
      { label: "Store ID", field: "storeid", sort: "asc", width: 75 },
      {
        label: "Nombre Tienda",
        field: "Nombretienda",
        sort: "asc",
        width: 200,
      },
      {
        label: "Marca",
        field: "marca",
        sort: "asc",
        width: 200,
      },
      {
        label: "Dirección",
        field: "direccion",
        sort: "asc",
        width: 200,
      },
      {
        label: "Teléfono",
        field: "telefono",
        sort: "asc",
        width: 200,
      },
      {
        label: "Nombre Comercial",
        field: "nombrecomercial",
        sort: "asc",
        width: 200,
      },
      { label: "Acciones", field: "acciones", sort: "asc", width: 150 },
    ],
    rows: currentItems.map((sucursal, index) => ({
      brandid: sucursal.brandid,
      storeid: sucursal.storeid,
      Nombretienda: sucursal.Nombretienda,
      marca: sucursal.marca,
      direccion: sucursal.direccion,
      telefono: sucursal.telefono,
      nombrecomercial: sucursal.nombrecomercial,
      acciones: (
        <Button color="danger" onClick={() => handleRemoveSucursal(index)}>
          Eliminar
        </Button>
      ),
    })),
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected)
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Sucursales</CardTitle>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#007ea7",
                      color: "white",
                      border: "1px solid rgb(46, 58, 73)",
                    }}
                    variant="success"
                    onClick={handleExportToExcel}
                    className="me-2"
                  >
                    Exportar a Excel
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => navigate("/portal/consultar-empresas")}
                  >
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Visualice y gestione la información de las sucursales
                disponibles en el sistema.
              </p>

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  entriesLabel="Mostrar registros"
                  searchLabel="Buscar:"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={[
                    "Mostrando registros del",
                    "al",
                    "de un total de",
                    "registros",
                  ]}
                  language={dataTableOptions}
                  paging={false}
                  displayEntries
                />
              )}
            </CardBody>
          </Card>
          <Row>
            <Col md={12}>
              <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Siguiente →"}
                pageCount={Math.ceil(sucursales.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(Sucursales)
