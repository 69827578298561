import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import axios from "axios"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes"
import { apiError, loginSuccess, logoutUserSuccess } from "./actions"
const baseURL = process.env.REACT_APP_API_URL

function* loginUser({ payload: { user, history } }) {
  try {
    console.log("Login URL:", `${baseURL}auth/login`)

    const response = yield call(axios.post, `${baseURL}auth/login`, {
      email: user.email,
      password: user.password,
    })

    console.log("Login response:", response.data)

    const { access, expiresIn = 3600 } = response.data // Proporcionar un valor predeterminado de 3600 segundos (1 hora)
    const expirationTime = new Date().getTime() + expiresIn * 1000

    console.log("Access token:", access)
    console.log("Expiration time:", expirationTime)

    console.log("Login me URL:", `${baseURL}usuarios/usuarios/me`)

    const userResponse = yield call(
      axios.get,
      `${baseURL}usuarios/usuarios/me`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      },
    )

    console.log("User response:", userResponse.data)

    const userData = { ...userResponse.data, access, expirationTime } // Añade el token de acceso y el tiempo de expiración a los datos del usuario
    localStorage.setItem("authUser", JSON.stringify(userData))
    yield put(loginSuccess(userData))
    history("/portal")
  } catch (error) {
    console.error("Login error:", error)
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history("/login")
    yield put(logoutUserSuccess())
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    // Manejar login social
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
}

export default authSaga
