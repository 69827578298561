import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Register1 from "../pages/AuthenticationInner/Register"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Empresas
import ConsultarEmpresas from "../pages/Empresas/ConsultarEmpresas"
import Sucursales from "../pages/Empresas/Sucursales"
import Empresa from "../pages/Empresas/Empresa"
import CrearEmpresa from "../pages/Empresas/CrearEmpresa"
import Resoluciones from "../pages/Empresas/Resoluciones"

//Comprobantes
import Comprobantes from "../pages/Comprobantes/Comprobantes"
import GenerarExcel from "../pages/Comprobantes/GenerarExcel"

//Administracion
import RegistrarUsuario from "../pages/Administracion/RegistrarUsuario"

//Extra Pages
import PagesBlank from "../pages/Extra Pages/pages-blank"
import Pages404 from "../pages/Extra Pages/pages-404"
import Pages500 from "../pages/Extra Pages/pages-500"

const userRoutes = [
  { path: "/portal", component: <Dashboard /> },

  // // //Empresas
  { path: "/portal/consultar-empresas", component: <ConsultarEmpresas /> },
  { path: "/portal/sucursales/:id", component: <Sucursales /> },
  { path: "/portal/empresa/:id", component: <Empresa /> },
  { path: "/portal/empresa/crear", component: <CrearEmpresa /> },
  { path: "/portal/resoluciones/:nit/:id", component: <Resoluciones /> },

  // // //Empresas
  { path: "/portal/consultar-comprobantes", component: <Comprobantes /> },
  { path: "/portal/reporte-comprobantes", component: <GenerarExcel /> },

  // // //Admoinistracion
  { path: "/portal/registro", component: <RegistrarUsuario /> },

  // // //profile
  { path: "/profile", component: <UserProfile /> },

  { path: "/pages-blank", component: <PagesBlank /> },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: <Navigate to="/portal" />,
  },
]

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },

  { path: "/pages-404", component: <Pages404 /> },
  { path: "/pages-500", component: <Pages500 /> },

  // Authentication Inner
  { path: "/pages-login", component: <Login1 /> },
  { path: "/pages-register", component: <Register1 /> },
  { path: "/page-recoverpw", component: <Recoverpw /> },
  { path: "/auth-lock-screen", component: <LockScreen /> },
]

export { userRoutes, authRoutes }
