export function isTokenValid() {
  const userData = JSON.parse(localStorage.getItem("authUser"))

  // console.log("userData", userData)

  if (!userData || !userData.expirationTime) return false

  const currentTime = new Date().getTime()
  return currentTime < userData.expirationTime
}
