import React, { useState, useEffect } from "react"
import axios from "axios"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import "./Facturas.css"
const baseURL = process.env.REACT_APP_API_URL

const GenerarExcel = props => {
  const navigate = useNavigate()

  const [documentos, setDocumentos] = useState([])
  const [empresas, setEmpresas] = useState([])
  const [archivos, setArchivos] = useState([])

  const [nitemisor, setNitemisor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [tipoDocumento, setTipoDocumento] = useState("TODOS LOS DOCUMENTOS")

  const [loading, setLoading] = useState(false)
  const [generating, setGenerating] = useState(false)

  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    console.log("Current user:", currentUser)

    if (currentUser.role === "SuperAdmin") {
      // Obtener empresas desde la API
      if (!currentUser.IdEmpresa || !currentUser.access) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      console.log("IDEMPRESA: ", currentUser.IdEmpresa)
      try {
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          {
            headers: {
              Authorization: `Bearer ${currentUser.access}`,
            },
          },
        )
        console.log("Empresas data fetched:", response.data)
        setEmpresas(response.data)
      } catch (error) {
        console.error("Error fetching empresas data:", error)
      }
    } else {
      // Establecer empresas desde currentUser.empresas
      if (!currentUser.empresas) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }
      try {
        console.log("Empresas data fetched:", currentUser.empresas)
        setEmpresas(currentUser.empresas)
      } catch (error) {
        console.error("Error setting empresas data:", error)
      }
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setGenerating(true)
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    console.log("Generating Excel with filters: ", {
      nitemisor: nitemisor,
      fechaInicio: fechaInicio ? fechaInicio.toISOString().split("T")[0] : "",
      fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
      tipoDocumento:
        tipoDocumento !== "TODOS LOS DOCUMENTOS" ? tipoDocumento : undefined,
    })

    try {
      const response = await axios.get(
        `https://timbrador.ls-da.com/hub-latam/rc1/invoice/recupera`,
        {
          params: {
            nitemisor: nitemisor,
            fechaInicio: fechaInicio
              ? fechaInicio.toISOString().split("T")[0]
              : "",
            fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
            tipoDocumento:
              tipoDocumento !== "TODOS LOS DOCUMENTOS"
                ? tipoDocumento
                : undefined,
          },
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        },
      )

      console.log("Excel generated:", response.data)

      setArchivos([...archivos, response.data]) // Asumiendo que response.data contiene información del archivo generado
    } catch (error) {
      console.error("Error generating Excel:", error)
    } finally {
      setGenerating(false)
    }
  }

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${currentUser ? `${currentUser.nombre} ${currentUser.apellido}` : "Usuario"}`

      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Generar Excel", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchTipoDocumento()
    fetchEmpresas()
  }, [props])

  const storedUser2 = localStorage.getItem("authUser")
  const currentUser2 = storedUser2 ? JSON.parse(storedUser2) : null

  const empresaOptions = empresas.map(empresa => {
    let value, label

    if (currentUser2.role === "SuperAdmin") {
      // Para SuperAdmin
      value = empresa.identificacion
      label = empresa.nombre
    } else {
      // Para otros roles
      value = empresa.idempresa.identificacion
      label = empresa.idempresa.nombre
    }

    return { value, label }
  })

  const handleNitemisorChange = selectedOption => {
    setNitemisor(selectedOption ? selectedOption.value : "")
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      zIndex: 1000,
    }),
    menu: provided => ({
      ...provided,
      zIndex: 1050,
    }),
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Generar Excel</CardTitle>
                <Button
                  color="danger"
                  onClick={() => navigate("/portal/dashboard")}
                >
                  Salir
                </Button>
              </div>
              <p className="card-title-desc">
                Utilice los filtros para generar un archivo Excel con la
                información de las facturas.
              </p>
              <hr />
              <Form onSubmit={handleSubmit} inline>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="nitemisor">NIT Emisor</Label>
                      <Select
                        id="nitemisor"
                        value={empresaOptions.find(
                          option => option.value === nitemisor,
                        )}
                        onChange={handleNitemisorChange}
                        options={empresaOptions}
                        classNamePrefix="select2-selection"
                        placeholder="Seleccione una empresa"
                        isClearable
                        styles={customStyles}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaInicio">Fecha Inicio</Label>
                      <DatePicker
                        selected={fechaInicio}
                        onChange={date => setFechaInicio(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="fechaFin">Fecha Fin</Label>
                      <DatePicker
                        selected={fechaFin}
                        onChange={date => setFechaFin(date)}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        required
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="tipoDocumento">Tipo Documento</Label>
                      <Input
                        type="select"
                        id="tipoDocumento"
                        value={tipoDocumento}
                        onChange={e => setTipoDocumento(e.target.value)}
                        required
                      >
                        <option value="TODOS LOS DOCUMENTOS">
                          Todos los Documentos
                        </option>
                        {documentos.map(type => (
                          <option key={type.code} value={type.code}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col
                    md={1}
                    className="d-flex align-items-end justify-content-end"
                  >
                    <Button type="submit" color="primary" disabled={generating}>
                      {generating ? "Generando..." : "Generar"}
                    </Button>
                  </Col>
                </Row>
              </Form>
              <hr />

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <div>
                  <div>Total archivos generados: {archivos.length}</div>

                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0 fixed-header"
                      data-pattern="priority-columns"
                    >
                      <Table
                        id="archivos-generados"
                        className="table table-striped table-bordered"
                      >
                        <Thead className="sticky-header">
                          <Tr>
                            <Th>#</Th>
                            <Th>Nombre Archivo</Th>
                            <Th>Fecha</Th>
                            <Th>Acciones</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {archivos.map((archivo, index) => (
                            <Tr key={index}>
                              <Td>{index + 1}</Td>
                              <Td>{archivo.nombre}</Td>
                              <Td>
                                {new Date(archivo.fecha).toLocaleString()}
                              </Td>
                              <Td>
                                <Button
                                  color="success"
                                  onClick={() =>
                                    window.open(archivo.url, "_blank")
                                  }
                                >
                                  Descargar
                                </Button>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(GenerarExcel)
